.center-max {
  height: auto;
  max-width: 100%;
  text-align: center;
}

.hide-under-750 {
  display: none;
}
@media (min-width: 750px) {
  .hide-under-750 {
    display: block;
  }
}

#t-gallery .testimonials {
  position: relative;
}
#t-gallery .testimonial {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  transition: none;
}
#t-gallery .testimonial:nth-child(3n + 1) {
  display: block;
}
#t-gallery img.full {
  display: none;
  position: absolute;
  left: calc(100% + 80px);
}
